import Navbar from "./Navbar";
import { getBaseUrl } from "../utils";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Footer from "./Footer";
import { useState, useEffect, useRef } from "react";

const Projects = () => {
  const [projectData, setProjectData] = useState([]);
  const [activeSection, setActiveSection] = useState("Completed");
  const [expandedIndex, setExpandedIndex] = useState(null);
  const swiperRef = useRef(null);

  const toggleDescriptionLength = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
      swiperRef.current?.autoplay.start();
    } else {
      setExpandedIndex(index);
      swiperRef.current?.autoplay.stop();
    }
  };

  const getProjectData = () => {
    axios
      .post(getBaseUrl() + "user_api/projectList", { status: activeSection })
      .then((res) => {
        if (res.data.status) setProjectData(res.data.results);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
  };

  useEffect(() => {
    getProjectData();
  }, [activeSection]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <section className="About_section_main_all"style={{overflow:'hidden'}}>
        <div className="container-fluid">
          <div className="row About_section_ttete">
            <div className="col-md-6 About_section_text" data-aos="zoom-out-left">
              <h1 style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(255, 255, 255) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}>A Glimpse into Our Work.</h1>
              <p style={{
                background: 'linear-gradient(135deg,rgb(255, 255, 255) 10%,rgb(255, 136, 0) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}>We've successfully delivered projects that showcase our capabilities.</p>
            </div>
            <div className="col-md-6 About_section_images"data-aos="zoom-in-right">
              <img src="/Images/Images provided by Bhaskar ji/projects_images.png" alt="projects" />
            </div>
          </div>
        </div>
      </section>

      <section className="project_section_main">
        <div className="container-fluid">
          <div className="row">
            {['Completed', 'In Progress', 'Upcoming'].map((section) => (
              <div className="col-sm-4" key={section} data-aos="zoom-in">
                <button onClick={() => setActiveSection(section)} className={activeSection === section ? "active" : ""}>
                  {section}
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="projects_main_section_slider">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12" style={{ position: 'relative' }}>
              <Swiper
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                spaceBetween={30}
                slidesPerView={2}
                speed={1500}
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                breakpoints={{
                  100: { slidesPerView: 1, spaceBetween: 20 },
                  620: { slidesPerView: 1, spaceBetween: 20 },
                  1024: { slidesPerView: 2, spaceBetween: 20 },
                }}
                modules={[Pagination, Autoplay, Navigation]}
                className="mySwiper"
                navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }}
              >

                {projectData.map((eachData, index) => (
                  <SwiperSlide key={index}>
                    <div className="Our_porjectes_section"data-aos="zoom-in-up">
                      <div className="Our_projects_items">
                        <img src={eachData.image} alt="project" style={{ borderRadius: '10px' }} />
                        <h4 style={{
                          background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                          '-webkit-background-clip': 'text',
                          color: 'transparent',
                          backgroundClip: 'text'
                        }}>{eachData.name}</h4>
                        <div>
                          <p style={{
                            background: 'linear-gradient(135deg,rgb(0, 0, 0) 10%,rgb(245, 135, 17) 100%)',
                            '-webkit-background-clip': 'text',
                            color: 'transparent',
                            backgroundClip: 'text'
                          }}>
                            {expandedIndex === index ? (
                              <span dangerouslySetInnerHTML={{ __html: eachData.description }} />
                            ) : (
                              <span dangerouslySetInnerHTML={{ __html: eachData.description.slice(0, 70) + '... ' }} />
                            )}
                            {expandedIndex !== index ? (
                              <span style={{ color: "red", cursor: "pointer" }} onClick={() => toggleDescriptionLength(index)}>
                                Read More
                              </span>
                            ) : (
                              <span style={{ color: "red", cursor: "pointer" }} onClick={() => toggleDescriptionLength(index)}>
                                Read Less
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="Area_images">
                          <img src="/Images/Images provided by Bhaskar ji/Area_images.svg" alt="area" />
                          <p>PLOT AREA</p>
                        </div>
                        <h5>{eachData.name}</h5>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="swiper-button-next">
                <ArrowRightAltIcon className="ArrowRightAltIcon" />
              </div>
              <div className="swiper-button-prev">
                <KeyboardBackspaceIcon className="KeyboardBackspaceIcon" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Projects;
