import Navbar from "./Navbar";
import { getBaseUrl } from "../utils";
import axios from "axios";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';





const Panel = ({ index, backgroundImage, setActivePanel, activePanel }) => {
  const handleClick = () => {
    setActivePanel(index);
  };

  return (
    <div
      className={`panel ${activePanel === index ? "active" : ""}`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      onMouseEnter={handleClick}
    >
    </div>
  );
};

const Services = () => {
  const [activeKey, setActiveKey] = useState(null);
  const toggleAccordion = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  const [activePanel, setActivePanel] = useState(0);

  const images = [
    "Images/Blog_img.png",
    "Images/Blog_img.png",
    "Images/Blog_img.png",
    "Images/Blog_img.png",
    "Images/Blog_img.png",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    getServiceData();
  }, []);
  const navigate = useNavigate();

  function clickOnService(ID) {
    console.log("serviceID----home------>", ID);
    navigate('/our_private_space', { state: { ID: ID } });
  }


  function getServiceData() {
    axios
      .post(getBaseUrl() + "user_api/serviceList")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setserviceData(res.data.results);
      });
  }
  const [serviceData, setserviceData] = useState([]);

  return (
    <div>
      <Navbar />
      <section className="Services_section_maain">
        <div className="container-fluid Services_section">
          <div className="row">
            <div className="col-12">
              <h1 
               style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(255, 255, 255) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }} data-aos="zoom-in">Service We Offer</h1>
              <p style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(255, 255, 255) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }} data-aos="zoom-in">
              Strategically located in the heart of Noida, Riana Towers exemplifies modern architecture, offering premium amenities and unmatched convenience for a refined urban lifestyle.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="Table_section_main">
        <div className="container-fluid">
          <div className="row">
            <div className="Header_table_main">
              <h1 style={{ padding: '0', marginTop: '20px' }}>
                RIANA Office Space<br></br>
                <span style={{ fontSize: '20px' }}> Corporate/ITES (Information Technology Enabled Services) Space</span>
              </h1>
            </div>
            <div className="col-md-12 Co_spacee_section ">
              <ul>
                <li style={{ padding: '0', margin: '0' }}><b>Key Features of ITES Spaces:</b></li>
                <li><b>High-Speed Internet & Network Infrastructure</b>- High-Speed Internet & Network Infrastructure </li>
                <li><b>Ergonomic Workstations  - </b>Designed for long working hours and productivity</li>
                <li><b>Meeting & Conference Rooms - </b>Equipped with video conferencing and collaboration tools.</li>
                <li><b>24/7 Security & Access - </b>Ensures operational continuity for global clients.</li>
                <li><b>Power Backup & Uninterrupted Supply - </b>Prevents downtime.</li>
                <li><b>Cafeteria & Recreational Areas  - </b>Supports employee well-being and relaxation.</li>
                <li><b>Compliance & Data Security  - </b> Meets industry standards for data protection.</li>

              </ul>
            </div>

          </div>
        </div>
      </section> */}
      {/* <section className="Faq_section">
        <div className="container-fluid FAQ_main_section">
          <div className="row">
            <div className="col-12">
              <h1 style={{ fontSize: '35px' }}>Frequently Asked Questions (FAQ’s)

                <p style={{ fontSize: '18px', paddingBottom: '20px' }}>Riana Towers Co-Working Space (Riana Qube)</p>
              </h1>
              <div className="accordion">
                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("1")}
                  >
                    <div className="Images_icons">
                      <h6>1. What types of office spaces do you offer?</h6>
                      {activeKey === "1" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "1" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        We offer a range of flexible office solutions, including private offices, shared co-working spaces, meeting rooms, and custom-built office suites. Whether you're a freelancer, a growing startup, or an established company, we have a space that fits your needs.
                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("2")}
                  >
                    <div className="Images_icons">
                      <h6>2.Why I choose Riana? </h6>
                      {activeKey === "2" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "2" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        We are a leading provider in the conventional as well as managed office spaces designed to meet diverse professional needs. Our offerings include:
                      </p>
                      <ol>
                        <li><b>100% Vastu-compliant spaces</b> : for a harmonious work environment</li>
                        <li><b>Prime connectivity</b> : with easy access, including walking-distance options</li>
                        <li><b>Gaming Zone</b> : for recreation and relaxation</li>
                        <li><b>Premium Office Cabins</b> : for a sophisticated and private workspace</li>
                        <li><b>Well-equipped Conference Rooms</b> : to facilitate productive meetings</li>
                        <li><b>Café Area</b> : to facilitate productive meetings</li>
                        <li><b>Designated Smoking Zone</b> : within the premises</li>
                        <li><b>24/7 Maintenance & Security</b> : to ensure a seamless work experience</li>
                      </ol>

                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("3")}
                  >
                    <div className="Images_icons">
                      <h6>
                        3.	Are there any hidden fees?
                      </h6>
                      {activeKey === "3" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "3" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        No! We believe in transparency. All fees will be clearly outlined in your lease agreement, and there are no hidden costs. Extra charges (e.g., for parking, meeting rooms, or additional services) will be specified in advance.
                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("4")}
                  >
                    <div className="Images_icons">
                      <h6>4.	Can I bring my own furniture and equipment?</h6>
                      {activeKey === "4" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "4" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        You’re welcome to bring personal items or equipment for your private office, provided it doesn’t interfere with other tenants or violate building policies. For co-working spaces, we provide all necessary furniture, but feel free to add personal touches to your space!
                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("5")}
                  >
                    <div className="Images_icons">
                      <h6>
                        5.	 Is parking available?
                      </h6>
                      {activeKey === "5" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "5" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        Many of our office locations offer parking for tenants. Some locations may provide free parking, while others charge an additional fee for reserved or daily parking. Be sure to check with us for parking availability at your chosen location.                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("6")}
                  >
                    <div className="Images_icons">
                      <h6>6.	 What happens if I need to terminate my lease early?</h6>
                      {activeKey === "6" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "6" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        If you need to terminate your lease early, please refer to your lease agreement for the termination policy. We offer flexible options, but some conditions, such as early termination fees, may apply. We encourage you to contact us to discuss your situation.
                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("7")}
                  >
                    <div className="Images_icons">
                      <h6>7.	Can I access my office outside of regular business hours?</h6>
                      {activeKey === "7" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "7" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        Yes, our office spaces are generally accessible 24/7 through secure keycard or access code entry, allowing you to work at your convenience. However, prior approval is required for after-hours access, and additional charges may apply for electricity, air conditioning, and other utilities.
                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("8")}
                  >
                    <div className="Images_icons">
                      <h6>8.	What should I do if I have technical issues?</h6>
                      {activeKey === "8" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "8" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        If you experience any technical issues, our team is available to provide support. For internet or IT-related problems, you can contact our support team, who will assist with troubleshooting or escalate the issue as needed.
                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("9")}
                  >
                    <div className="Images_icons">
                      <h6>
                        9.	How do I contact customer support?
                      </h6>
                      {activeKey === "9" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "9" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        You can reach our customer support team by phone, email, or through the contact form on our website. We're available to assist you with inquiries regarding space availability, lease terms, or any other questions you may have.
                      </p>
                    </div>
                  )}
                </div>


              </div>
            </div>
          </div>
        </div>
      </section> */}



      <section className="services_one_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1
               style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }} data-aos="zoom-in-up">
              Certified Excellence</h1>
              <p
              
              style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }} data-aos="zoom-in-up">
                Certified Excellence signifies a commitment to quality,{" "}
                reliability, and exceptional standards.
              </p>
            </div>
            {serviceData.map((eachData) => {
              return (
                <>
                  <div className="col-md-6 services_section_all">
                    <div className="Services_section" data-aos="zoom-in-right">
                      <img
                        src={eachData.image}
                        alt="images"
                        style={{borderRadius:'10px'}}
                       
                      />
                      {/* <Link to="/our_private_space"> */}
                      <h5  style={{
                background: 'linear-gradient(135deg,rgb(255, 255, 255) 10%,rgb(255, 255, 255) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}>{eachData.name}</h5>
                      <button onClick={() => clickOnService(eachData._id)} >
                        <NorthEastIcon className="ArrowRightAltIcon" />
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </>
              );
            })}
            {/* <div className="col-md-4 services_section_all">
              <div className="Services_section">
                <img
                  src="/Images/Images provided by Bhaskar ji/Services_2.png"
                  alt="images"
                />
                <Link to="/our_private_space">
                  <h5>Private Space</h5>
                  <button>
                    <NorthEastIcon className="ArrowRightAltIcon" />
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-4 services_section_all">
              <div className="Services_section">
                <img
                  src="/Images/Images provided by Bhaskar ji/services_3.png"
                  alt="images"
                />
                <Link to="/our_private_space">
                  <h5>Private Space</h5>
                  <button>
                    <NorthEastIcon className="ArrowRightAltIcon" />
                  </button>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* <section className="Table_section_main">
        <div className="container-fluid">
          <div className="row">
            <div className="Header_table_main">
              <h1 style={{ padding: '0' }}>
                RIANA Qube<br></br>
                <p style={{ fontSize: '20px', paddingTop: '10px' }}> Riana Qube is a leading, dynamic, premium co working space specially designed for entrepreneurs, freelancers and other businesses. We offer a collaborative and productive environment with modern amenities and networking opportunities. No matter the size of your company or industry, engage, empower and thrive with Riana Qube.</p>
              </h1>
            </div>
            <div className="col-md-12 Co_spacee_section">
              <ul>
                <li style={{ padding: '0', margin: '0' }}><b>Key Features of RIANA Qube: </b></li>
                <li><b>Flexible Membership Options</b>- Choose monthly plans that fit your working style. </li>
                <li><b>High-Speed Internet- </b>Reliable and fast Wi-Fi for seamless work.</li>
                <li><b>Ergonomic Furniture - </b>Comfortable chairs and adjustable desks.</li>
                <li><b>Private Cabins- </b>For focused work or team meetings.</li>
                <li><b>Conference Rooms- </b>Conference Rooms – Professionally equipped spaces with modern audio-visual technology for hosting meetings, presentations, and brainstorming sessions.</li>
                <li><b>Printing and Scanning - </b>On-site access to printers, scanners, and copiers.</li>

              </ul>
            </div>

          </div>
        </div>
      </section> */}

      {/* <section className="Faq_section">
        <div className="container-fluid FAQ_main_section">
          <div className="row">
            <div className="col-12">
              <h1 style={{ fontSize: '35px', paddingBottom: '30px' }}>Frequently Asked Questions (FAQ) Riana Offices Space</h1>
              <div className="accordion">
                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("1")}
                  >
                    <div className="Images_icons">
                      <h6>1.	What is Riana Qube?</h6>
                      {activeKey === "1" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "1" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        Riana Towers Co-Working Space Riana Qube is a modern, fully equipped shared workspace designed for freelancers, startups, remote workers, and entrepreneurs looking for a productive and professional environment.                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("2")}
                  >
                    <div className="Images_icons">
                      <h6>2.	What are the operating hours?</h6>
                      {activeKey === "2" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "2" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        Our co-working space is open Monday to Friday from 8:00 AM to 8:00 PM and Saturday from 9:00 AM to 5:00 PM. We are closed on Sundays and public holidays.
                      </p>

                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("3")}
                  >
                    <div className="Images_icons">
                      <h6>
                        3.	Can I access the space 24/7?
                      </h6>
                      {activeKey === "3" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "3" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        24/7 access is available for selected membership plans. Please contact our team for more details.                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("4")}
                  >
                    <div className="Images_icons">
                      <h6>4.	What types of membership plans do you offer?</h6>
                      {activeKey === "4" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "4" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        We offer flexible membership options, including:
                      </p>
                      <ol>
                        <li><b>Hot Desk</b> (Daily/Weekly/Monthly)</li>
                        <li><b>Dedicated Desk</b></li>
                        <li><b>Private Office</b></li>
                        <li>
                          <b>Meeting Room Rentals</b>
                        </li>
                      </ol>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("5")}
                  >
                    <div className="Images_icons">
                      <h6>
                        5.	Is a security deposit required?
                      </h6>
                      {activeKey === "5" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "5" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        Yes, a security deposit is required for monthly memberships and private offices. The deposit amount varies depending on the selected plan. For more details, please contact us at 9205219711 or email bhaskar@rianatowers.com.
                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("6")}
                  >
                    <div className="Images_icons">
                      <h6>6.	What facilities are available at Riana Qube?</h6>
                      {activeKey === "6" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "6" && (
                    <div className="accordion-body Body_text_section">
                      <ol>
                        <li>•	High-speed internet</li>
                        <li>•	Meeting rooms and conference rooms</li>
                        <li>•	Private offices</li>
                        <li>•	Printing and scanning services</li>
                        <li>•	Lounge and relaxation areas</li>
                        <li>•	Coffee and tea station</li>

                      </ol>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("7")}
                  >
                    <div className="Images_icons">
                      <h6>7.	Do you provide high-speed internet and Wi-Fi?</h6>
                      {activeKey === "7" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "7" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        Yes, we offer high-speed internet with secure Wi-Fi access for all members.                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("8")}
                  >
                    <div className="Images_icons">
                      <h6>8.	Do you have a pantry or café?</h6>
                      {activeKey === "8" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "8" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        Yes, we have a self-service pantry with complimentary coffee, tea, and water. Food options may be available nearby.                      </p>
                    </div>
                  )}
                </div>

                <div className="accordion-item">
                  <div
                    className="accordion-header Header_section_faq"
                    onClick={() => toggleAccordion("9")}
                  >
                    <div className="Images_icons">
                      <h6>
                        9.	 Is the space secure and monitored?
                      </h6>
                      {activeKey === "9" ? (
                        <ArrowDropUpIcon className="Iconses" />
                      ) : (
                        <ArrowDropDownIcon className="Iconses" />
                      )}
                    </div>
                  </div>
                  {activeKey === "9" && (
                    <div className="accordion-body Body_text_section">
                      <p>
                        Yes, we have CCTV surveillance, secure entry access, and on-site staff to ensure safety.                      </p>
                    </div>
                  )}
                </div>


              </div>
            </div>
          </div>
        </div>
      </section> */}





      {/* Gallery section coding start
      <section className="Gallery_heding_section_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <h1 className="gallery">Gallery</h1>
              <p>
                Professionals may interact, invent, and flourish in a vibrant
                and motivating environment at our co-working space.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="accordion-slider">
                <div className="accordion">
                  <ul>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}


      {/* <section className="Gallery_heding_section_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <h1 className="gallery">Gallery</h1>
              <p>
                Professionals may interact, invent, and flourish in a vibrant
                and motivating environment at our co-working space.
              </p>
            </div>
          </div>
        </div>
      </section>

  <div className="container-fluid">
    <div className="row">
      <div className="col-12 Gallery_new">
        <div className="gallory_new_all">
        {images.map((image, index) => (
        <Panel
          key={index}
          index={index}
          backgroundImage={image}
          setActivePanel={setActivePanel}
          activePanel={activePanel}
         
        />
      ))}
        </div>
  
      </div>
    </div>
  
    </div> */}


      {/* Start testimonial section  */}
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1>They Satisfied with service</h1>
            <p>
              For our staff, our co-working space has changed everything. The
              contemporary amenities, adaptable workstations, and encouraging
              community have produced the perfect setting for cooperation and
              production.
            </p>
          </div>
          <div className="col-md-4">
            <div className="Testimonial_images">
              <img src="/Images/Testimonial_header_img.png" alt="images" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Swiper
              spaceBetween={30}
              slidesPerView={2}
              speed={3000}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                loop: true,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                620: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                720: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide className="slider_main">
                <div className="Testimonial_section_slider">
                  <div className="Testimonial_section_slider_items">
                    <p>
                      Led by visionary architects of efficiency, managed spaces
                      embody comfort, innovation, and ease—each element crafted
                      for harmony.
                    </p>

                    <div className="Testimonial_name">
                      <div className="Testimonil_images">
                        <img
                          src="/Images/Images provided by Bhaskar ji/Testimonial2.png"
                          alt="images"
                        />
                      </div>
                      <div className="Testimonial_name_heading">
                        <h5>Sukhdev Pathak</h5>
                        <p>CEO & Founder</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slider_main">
                <div className="Testimonial_section_slider">
                  <div className="Testimonial_section_slider_items">
                    <p>
                      Driven by pioneers in efficiency, managed spaces blend
                      comfort, innovation, and ease—every detail designed for
                      perfect harmony.
                    </p>

                    <div className="Testimonial_name">
                      <div className="Testimonil_images">
                        <img
                          src="/Images/Images provided by Bhaskar ji/Testimonial.png"
                          alt="images"
                        />
                      </div>
                      <div className="Testimonial_name_heading">
                        <h5>Nirmala S. Karthik</h5>
                        <p>Manager</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slider_main">
                <div className="Testimonial_section_slider">
                  <div className="Testimonial_section_slider_items">
                    <p>
                      Riana Towers transformed our office into a modern,
                      inspiring workspace. Their team of experts handled every
                      detail, from furniture selection to IT setup. We're more
                      productive than ever.
                    </p>

                    <div className="Testimonial_name">
                      <div className="Testimonil_images">
                        <img
                          src="/Images/Images provided by Bhaskar ji/Testimonial1.png"
                          alt="images"
                        />
                      </div>
                      <div className="Testimonial_name_heading">
                        <h5>Prisha Aggarwal</h5>
                        <p>MD & CEO</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="container-fluid Brand_partner">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1>Our Brand Partner</h1>
            <p>
              Committed to fostering collaboration, creativity, and
              productivity, our partner offers a diverse range of workspace
              solutions, including:
            </p>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      
      <section className="Slider_full_section">
        <div className="container-fluid Brand_partner_slider">
          <div className="row">
            <div className="col-12">
              <div id="infinite" class="highway-slider">
                <div class="container-fluid highway-barrier">
                  <ul class="highway-lane">
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                   

                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
};
export default Services;
