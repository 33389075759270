import Slider from "react-slick";
import { getBaseUrl } from "../utils";
import axios from "axios";
import { settings } from "../DataSlide/SliderData";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = () => {

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [shownevbtn, setshownevbtn] = useState(true);


  useEffect(() => {
    // Check if the modal was previously shown
    const hasSeenPopup = sessionStorage.getItem("hasSeenPopup");

    if (!hasSeenPopup) {
      // Show modal after 1 second (1000ms)
      const timer = setTimeout(() => {
        setShowModal(true);
        setshownevbtn(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, []);


  const closeModal = () => {
    console.log("hit close")

    setShowModal(false);
    setshownevbtn(true);
    sessionStorage.setItem("hasSeenPopup", "true"); // Mark modal as seen
    //window.location.reload();
    console.log("refress")
    // Prevent accidental double-clicks

  };

  // Prevent modal from closing if clicked outside or anywhere other than the button
  const handleModalClick = (e) => {
    // Prevent closing the modal if clicked outside the modal content
    if (e.target.id === "modalpopup") {
      e.stopPropagation();
    }
  };





  const location = useLocation();
  function scrollToContact() {
    var pathname = location.pathname;
    console.log("pathname--->", pathname);
    if (pathname == '/') {
      window.scrollTo({ top: 5280, behavior: 'smooth' });
    } else {
      navigate('/', {})
      const timer = setTimeout(() => {
        window.scrollTo({ top: 5280, behavior: 'smooth' });
      }, 1000);
    }
  }
  const [affix, setAffix] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setAffix(true);
    } else {
      setAffix(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getCategoryService()
    window.scrollTo(0, 0);
  }, [location]);

  function getCategoryService() {
    axios
      .get(getBaseUrl() + "user_api/CategoryService")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setcategoryService(res.data.results);
      });
  }
  const [categoryService, setcategoryService] = useState([]);

  //const location = useLocation();

  const getActiveClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const handleImageClick = () => {
    if (shownevbtn) {

      if (location.pathname === '/our_services') {
        window.scrollTo({
          top: 850,
          behavior: 'smooth',
        });
      } else {
        navigate('/our_services');
      }
    }

  };

  return (
    <>
      <div className={`navbar_main ${affix ? "affix" : ""}`}>
        <div className="container-fluid">
          <div className="row">
            <nav>
              <div className="wrapper">
                <div className="logo">
                  <Link to="/">
                    <img src="/Images/Logo.png" alt="Logo" />
                  </Link>
                </div>
                <input type="radio" name="slider" id="menu-btn" />
                <input type="radio" name="slider" id="close-btn" />
                <ul className="nav-links">
                  <label htmlFor="close-btn" className="btn close-btn">
                    <CloseIcon style={{ fontSize: '40px' }} />
                  </label>
                  <li className={getActiveClass("/")}>
                    <Link to="/">Home</Link>
                  </li>
                  <li className={getActiveClass("/our_about")}>
                    <Link to="/our_about" onClick={(e) => !shownevbtn && e.preventDefault()}>About Us</Link>
                  </li>
                  <li className={getActiveClass("/our_partnership")}>
                    <Link to="/our_partnership" onClick={(e) => !shownevbtn && e.preventDefault()}>Partnerships</Link>
                  </li>
                  <li >
                    <a className="desktop-item">
                      What we do
                    </a>
                    <input type="checkbox" id="showDrop" />
                    <label htmlFor="showDrop" className="mobile-item">
                      What we do
                    </label>

                    <ul className="drop-menu">
                      <div className="Cout_out_section"></div>
                      <li>
                        <Link to="/our_services" onClick={(e) => !shownevbtn && e.preventDefault()}>
                          Service
                        </Link>
                      </li>
                      <li>
                        <Link to="/our_project" onClick={(e) => !shownevbtn && e.preventDefault()}>
                          Projects
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        if (shownevbtn) {
                          scrollToContact();
                        }
                      }}
                      className="login-button"
                    >
                      Contact Us
                    </button>
                  </li>
                </ul>
                <label htmlFor="menu-btn" className="btn menu-btn">
                  <MenuIcon className="menu_bar" style={{ fontSize: '40px' }} />
                </label>
              </div>
            </nav>
          </div>
        </div>

        <div className="container-fluid slider_section_nav">
          <div className="row">
            <div className="col-12 slider-container" style={{ padding: '30px', paddingTop: '0px', paddingBottom: '0px' }}>
              <Slider {...settings}>
                {categoryService.map((eachData) => {
                  return (
                    <>
                      <div className="slider_section_div" onClick={handleImageClick}>
                        {/* <Link to="/our_services"> */}
                        <center>
                          <img
                            src={eachData.image}
                            alt="logo images"
                          />
                          <p style={{
                            background: 'linear-gradient(135deg,rgb(253, 239, 222) 10%,rgb(245, 120, 17) 100%)',
                            '-webkit-background-clip': 'text',
                            color: 'transparent',
                            backgroundClip: 'text'
                          }}>{eachData.name}</p>
                        </center>
                        {/* </Link> */}
                      </div>
                    </>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* Popup Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          width: "100%",
          height: "100vh",
          position: "fixed",
          margin: '100px auto',
          top: "0",
          left: "0",
          zIndex: 1050,
          visibility: showModal ? "visible" : "hidden", // Show only when modal is open
        }}
        id="modalpopup"
        onClick={handleModalClick} // Handle click on the background area
      >
        {/* Modal */}
        <div
          className={`modal ${showModal ? "show" : ""}`}
          id="modalpopup"
          tabIndex="-1"
          style={{
            display: showModal ? "block" : "none",
            zIndex: 2000,
            position: "relative",
            top: "0",
          }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content" style={{ zIndex: 9999999999 }}>
              <div className="modal-body p-4" style={{ zIndex: 9999999999 }}>
                <h4 className="Heading___Disclaimer">Disclaimer</h4>
                <p style={{ fontSize: "14px", fontFamily: "Zodiak" }}>
                  This is to inform user/customer(s) that www.anantrajlimited.com is the only
                  official website of Riana Towers. User/Customer(s) are cautioned and advised not
                  to rely upon any information stated on any other websites which may appear to be
                  similar to the Company's official website. The information contained in such
                  websites may be misleading/false and user/customer(s) may suffer loss if they rely
                  on such information. In the event user/customer(s) come across any such websites
                  similar to Company's official website containing its brand name/logo or any other
                  information, then kindly contact and inform us on Bhaskar@rianatowers.com in
                  order to enable the Company to take necessary action.
                </p>
                <h4 className="Heading___Disclaimer">Terms of use</h4>
                <p style={{ fontSize: "14px", fontFamily: "Zodiak" }}>
                  The terms and conditions appearing on this page (together with the documents
                  referred to in them) set out the terms on which you may make use of this website,
                  whether as a guest or a registered user. Use of this website includes accessing,
                  browsing, or registering to use our website. So, please read these terms carefully
                  before you start to use our website, as these will apply to your use of our
                  website. If you do not agree to these terms, you must not use our website.
                </p>
              </div>
              <div className="p-2" style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                <p
                  style={{ fontSize: "15px", fontFamily: "Zodiak", color: "red" }}
                  onClick={closeModal} // Close the modal when this is clicked
                >
                  I HAVE READ AND UNDERSTOOD THE DISCLAIMER AND I AGREE
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Popup end section

      {/* end Navbar section */}
    </>
  );
};
export default Navbar;
