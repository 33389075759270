import Navbar from "./Navbar";
import axios from "axios";
import "../App.css";
import "../Style.css";
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Autoplay } from "swiper/modules";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { getBaseUrl } from "../utils";
import { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const Slide = (props) => {
  return (
    <React.Fragment>
      <img src={props.image.link} alt="Sliderr_image" />
      <h1>
        {props.image.title}
        <span className="Button_Exp_slider">
          <button onClick={props.slidePrev}>
            <KeyboardBackspaceIcon className="KeyboardBackspaceIcon" />
          </button>
          <button onClick={props.slideNext}>
            <ArrowRightAltIcon className="ArrowRightAltIcon" />
          </button>
        </span>
      </h1>
    </React.Fragment>
  );
};
const Layout = () => {


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => {
        return prev + 1 === slides.length ? 0 : prev + 1;
      });
    }, 4000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [loadingHome, setLoadingHome] = useState(true);

  useEffect(() => {
    const Islodingdata = setTimeout(() => {
      setLoadingHome(false);
    }, 1000);
    return () => clearTimeout(Islodingdata);
  }, []);


  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const animationClasses = ["animate__fadeInUp", "animate__fadeInLeft", "animate__fadeInRight"];
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const slides = [
    {
      id: 1,
      link: "/Images/Images provided by Bhaskar ji/About_slider_2.png",
      ImagesIcon: "ApartmentIcon",
      setting: "15",
      cabin: "4",
    },
    {
      id: 2,
      link: "/Images/Images provided by Bhaskar ji/About_slider1.png",
      ImagesIcon: "ApartmentIcon",
      setting: "315",
      cabin: "44",
    },
    {
      id: 3,
      link: "/Images/Images provided by Bhaskar ji/About_slider_3.png",
      ImagesIcon: "ApartmentIcon",
      setting: "215",
      cabin: "78",
    },
  ];
  const slideNext = (e) => {
    setCurrentSlide((prev) => {
      return prev + 1 === slides.length ? 0 : currentSlide + 1;
    });
  };
  const slidePrev = (e) => {
    setCurrentSlide((prev) => {
      return prev === 0 ? slides.length - 1 : currentSlide - 1;
    });
  };
  useEffect(() => {
    gethomeData();
    window.scrollTo(0, 0);
  }, []);



  function gethomeData() {
    axios.get(getBaseUrl() + "user_api/homeData").then((res) => {
      console.log("res---->", res.data);
      if (res.data.status) settestimonlsData(res.data.testimonls);
      setbanner(res.data.banner);
      setfaq(res.data.faq);
      setblog(res.data.latestBlog);
      setbrandData(res.data.brandData);
      setCategoryService(res.data.CategoryService);
    });
  }
  const [testimonlsData, settestimonlsData] = useState([]);
  const [CategoryService, setCategoryService] = useState([]);

  const [bannerData, setbanner] = useState([]);
  const [faqData, setfaq] = useState([]);
  const [blogData, setblog] = useState([]);
  const [brandData, setbrandData] = useState([]);

  const [activeKey, setActiveKey] = useState(null);
  const toggleAccordion = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };
  const swiperRef = useRef(null);
  function blogPageset_data(blogData) {
    console.log("blogData----------->", blogData);
    navigate("/our_blog_details", { state: { blogData: blogData } });
  }

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.autoplay) {
      swiperRef.current.autoplay.start();
    }
  }, []);
  useEffect(() => {
    console.log("Page loaded, Swiper is ready");
  }, []);


  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: false,
      mirror: true,
    });

    setTimeout(() => {
      AOS.refreshHard();
    }, 200);
  }, []);


  return (
    <div>
      {loadingHome ? (
        <div className="loder_first__section">
          <div className="loader__Section__main">
            <div className="loading-container">
              <div className="loading-text">
                <span>L</span>
                <span>O</span>
                <span>A</span>
                <span>D</span>
                <span>I</span>
                <span>N</span>
                <span>G</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Navbar />
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop={bannerData.length > 1}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            speed={1500}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          >
            {bannerData.map((eachData, index) => (
              <SwiperSlide key={`slide-${index}`}>
                <div className="slider_section_main">
                  <div className="media_container">
                    {eachData.type === "Image" ? (
                      <img
                        src={eachData.img}
                        alt={`banner-${index}`}
                        style={{
                          height: "100vh",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : eachData.type === "Video" ? (
                      <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{
                          width: "100%",
                          height: "100vh",
                          objectFit: "cover",
                        }}
                        className="video_section"
                      >
                        <source src={eachData.img} type="video/mp4" />
                      </video>
                    ) : null}
                  </div>

                  <div className="text_overlay">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12 Hero_section_main_hero">
                          <h1>{eachData.title}</h1>
                          <div
                            style={{
                              textAlign: "left",
                              background:
                                "linear-gradient(135deg,rgb(255, 255, 254) 10%,rgb(255, 221, 187) 100%)",
                              WebkitBackgroundClip: "text",
                              color: "transparent",
                              backgroundClip: "text",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: eachData.description,
                            }}
                            className={`Heading_hero_top_section animate__animated ${activeIndex === index
                              ? animationClasses[index % animationClasses.length]
                              : ""
                              }`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <section className="About_section_main" style={{overflow:'hidden'}}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 Expreance_text_section" data-aos="zoom-out-left">
                  <h1
                    style={{
                      background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                      '-webkit-background-clip': 'text',
                      color: 'transparent',
                      backgroundClip: 'text'
                    }}>Passionate About Making A Positive Impact</h1>
                  <p
                    style={{
                      background: 'linear-gradient(135deg,rgb(32, 32, 30) 10%,rgb(252, 167, 82) 100%)',
                      '-webkit-background-clip': 'text',
                      color: 'transparent',
                      backgroundClip: 'text'
                    }}>
                    The purpose of our co-working environment is to encourage
                    innovation, teamwork, and efficiency. We provide a contemporary,
                    motivating setting with cutting-edge features like conference
                    areas, cozy workstations, and fast internet
                  </p>
                  <div className="Expreanse_section_main">
                    <div className="Exprteans_section">
                      <div className="Icons_Expreansces" ref={ref}>
                        <img src="/Images/tactic.png" alt="images" />
                        <h1
                          style={{
                            background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                            '-webkit-background-clip': 'text',
                            color: 'transparent',
                            backgroundClip: 'text'
                          }}
                        >{inView ? <CountUp start={0} end={200} duration={23} /> : "0"}+</h1>

                      </div>
                      <p
                        style={{
                          background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                          '-webkit-background-clip': 'text',
                          color: 'transparent',
                          backgroundClip: 'text'
                        }}
                      >Projects</p>
                    </div>
                    <div className="Exprteans_section">
                      <div className="Icons_Expreansces">
                        <img src="/Images/kid_star.png" alt="images" />
                        <h1
                          style={{
                            background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                            '-webkit-background-clip': 'text',
                            color: 'transparent',
                            backgroundClip: 'text'
                          }}
                        >{inView ? <CountUp start={0} end={200} duration={23} /> : "0"}+</h1>
                      </div>
                      <p
                        style={{
                          background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                          '-webkit-background-clip': 'text',
                          color: 'transparent',
                          backgroundClip: 'text'
                        }}
                      >Happy Clients</p>
                    </div>
                    <div className="Exprteans_section">
                      <div className="Icons_Expreansces">
                        <img src="/Images/Qustion.png" alt="images" />
                        <h1
                          style={{
                            background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                            '-webkit-background-clip': 'text',
                            color: 'transparent',
                            backgroundClip: 'text'
                          }}
                        >24/7</h1>
                      </div>
                      <p
                        style={{
                          background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                          '-webkit-background-clip': 'text',
                          color: 'transparent',
                          backgroundClip: 'text'
                        }}
                      >Support Help</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="zoom-in-right">
                  <div className="slider_images">
                    <div className="slider_section">
                      <Slide
                        image={slides[currentSlide]}
                        slideNext={slideNext}
                        slidePrev={slidePrev}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="Gallery_heding_section_main">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12" data-aos="zoom-in">

                  <h1 className="gallery"
                    style={{
                      background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                      '-webkit-background-clip': 'text',
                      color: 'transparent',
                      backgroundClip: 'text'
                    }}>Gallery</h1>
                  <p
                    style={{
                      background: 'linear-gradient(135deg,rgb(32, 32, 30) 10%,rgb(252, 167, 82) 100%)',
                      '-webkit-background-clip': 'text',
                      color: 'transparent',
                      backgroundClip: 'text'
                    }}>
                    Professionals may interact, invent, and flourish in a vibrant
                    and motivating environment at our co-working space.
                  </p>
                </div>
              </div>
            </div>
            <div className="container-fluid" data-aos="zoom-in-down">
              <div className="row">
                <div className="col-12">
                  <div className="accordion-slider">
                    <div className="accordion">
                      <ul>
                        <li>
                          <div></div>
                        </li>
                        <li>
                          <div></div>
                        </li>
                        <li>
                          <div></div>
                        </li>
                        <li>
                          <div></div>
                        </li>
                        <li>
                          <div></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 col-sm-12 Testmonial_section_main" data-aos="zoom-in">
                <h1
                  style={{
                    background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>They Satisfied with service</h1>
                <p
                  style={{
                    background: 'linear-gradient(135deg,rgb(32, 32, 30) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text',
                    paddingRight: '20px'
                  }}>
                  For our staff, our co-working space has changed everything. The
                  contemporary amenities, adaptable workstations, and encouraging
                  community have produced the perfect setting for cooperation and
                  production.
                </p>
              </div>
              <div className="col-md-4" data-aos="zoom-in-down">
                <div className="Testimonial_images">
                  <img src="/Images/Testimonial_header_img.png" alt="images" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={2}
                  speed={3000}
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    loop: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    100: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    620: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    720: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Pagination, Autoplay]}
                  className="mySwiper"
                >
                  {testimonlsData.map((eachData) => {
                    return (
                      <>
                        <SwiperSlide className="slider_main" data-aos="zoom-in-left">
                          <div className="Testimonial_section_slider">
                            <div className="Testimonial_section_slider_items">
                              <p
                                style={{
                                  background: 'linear-gradient(135deg,rgb(32, 32, 30) 10%,rgb(252, 167, 82) 100%)',
                                  '-webkit-background-clip': 'text',
                                  color: 'transparent',
                                  backgroundClip: 'text'
                                }}
                              >{eachData.feedback}</p>

                              <div className="Testimonial_name">
                                <div className="Testimonil_images" >
                                  <img src={eachData.img} alt="images" style={{ borderRadius: '7px' }} />
                                </div>
                                <div className="Testimonial_name_heading">
                                  <h5
                                    style={{
                                      background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                                      '-webkit-background-clip': 'text',
                                      color: 'transparent',
                                      backgroundClip: 'text'
                                    }}
                                  >{eachData.user_name}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
          <div className="container-fluid Brand_partner">
            <div className="row">
              <div className="col-md-12 Testmonial_section_main" data-aos="zoom-in">
                <h1 style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>
                  Our Brand Partner
                </h1>

                <p
                  style={{
                    background: 'linear-gradient(135deg,rgb(32, 32, 30) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text',
                    paddingRight: '20px'
                  }}
                  className="brand___patner__main">
                  Committed to fostering collaboration, creativity, and
                  productivity, our partner offers a diverse range of workspace
                  solutions, including:
                </p>
              </div>
            </div>
          </div>
          <section className="Slider_full_section">
            <div className="container-fluid Brand_partner_slider">
              <div className="row">
                <div className="col-12" data-aos="zoom-in-down">
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={4}
                    loop={brandData.length > 1}
                    autoplay={{
                      delay: 1,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      100: { slidesPerView: 2, spaceBetween: 20 },
                      620: { slidesPerView: 4, spaceBetween: 20 },
                    }}
                    speed={1500}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {/* Map through bannerData */}
                    {brandData.map((eachData, index) => (
                      <SwiperSlide key={`slide-${index}`} >
                        <div className="slider_section_main" style={{ display: 'flex', alignItems: 'center', height: '90px', justifyContent: 'center' }}>
                          <img src={eachData.img} />
                        </div>
                      </SwiperSlide>
                    ))}

                  </Swiper>
                </div>
              </div>
            </div>
          </section>
          <div className="container-fluid Blog_main_section">
            <div className="row">
              <div className="col-md-8 Testmonial_section_main" data-aos="zoom-in">
                <h1
                  style={{
                    background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>Blog Section</h1>
                <p
                  style={{
                    paddingRight: '20px',
                    background: 'linear-gradient(135deg,rgb(32, 32, 30) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}
                >
                  Subscribe to my blog to receive new posts directly to your inbox.
                </p>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12" data-aos="zoom-in-down">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={3}
                  speed={1500}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    loop: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    100: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    620: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    720: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Pagination, Autoplay]}
                  className="mySwiper"
                >
                  {blogData.map((eachData) => {
                    return (
                      <>
                        <SwiperSlide className="slider_main">
                          <div className="Blog_section_slider">
                            <div className="Blog_section_slider_items">
                              <div className="Blog_section_slider_Img">
                                <img src={eachData.image} alt="blog" />
                              </div>
                              <p className="Blog_header"
                              >
                                {eachData.name.slice(0, 45) + "..."}
                              </p>
                              <p className="Dec_section_blog"
                                style={{
                                  background: 'linear-gradient(135deg,rgb(32, 32, 30) 10%,rgb(252, 167, 82) 100%)',
                                  '-webkit-background-clip': 'text',
                                  color: 'transparent',
                                  backgroundClip: 'text'
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      eachData.description.slice(0, 60) + "...",
                                  }}
                                />
                              </p>
                              {/* <Link to="/our_blog"> */}
                              <button onClick={() => blogPageset_data(eachData)}>
                                Read the Full Blog{" "}
                                <ArrowRightAltIcon className="ArrowRightAltIcon" />
                              </button>
                              {/* </Link> */}
                            </div>
                          </div>
                        </SwiperSlide>
                      </>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
          <section className="About_section_main_footer" data-aos="zoom-in-down">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="About_main_footer">
                    <div className="About_Footer_section">
                      <h1
                        style={{
                          background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                          '-webkit-background-clip': 'text',
                          color: 'transparent',
                          backgroundClip: 'text'
                        }}>About Riana Towers</h1>
                      <p style={{ paddingRight: '10px' }}>
                        Riana Towers is a prestigious commercial landmark that
                        offers world-class office spaces in Noida. Designed to
                        inspire innovation and productivity, our state-of-the-art
                        facilities provide the perfect environment for businesses of
                        all sizes.
                      </p>
                    </div>
                    <div className="Submit_section">
                      <Link to="/our_about">
                        <div>
                          About us <NorthEastIcon className="ArrowRightAltIcon" />{" "}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </div>
  );
};
export default Layout;
