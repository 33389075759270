import Navbar from "./Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import Footer from "./Footer";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { getBaseUrl } from "../utils";
import axios from "axios";
import { useState } from "react";

const Partnership = () => {




  function gethomeData() {
    axios
      .get(getBaseUrl() + "user_api/homeData2")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          settestimonlsData(res.data.testimonls);
        setbrandData(res.data.brandData);


      });
  }
  const [testimonlsData, settestimonlsData] = useState([]);
  const [brandData, setbrandData] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0);
    gethomeData()
  }, []);
  return (
    <div>
      <Navbar />
      <section className="About_section_main_all">
        <div className="container-fluid">
          {/* <div className="row About_section_ttete">
            <div className="col-md-6 About_section_text">
              <h1>Partnering for Success.</h1>
              <p>
                A partnership is a formal arrangement between two or more
                parties to manage and operate a business and share its profits
                and liabilities.
              </p>
              <HashLink smooth to="#footer">
  <button>
    Partnership with us
    <NorthEastIcon className="ArrowRightAltIcon" />
  </button>
</HashLink>
            </div>
            <div className="col-md-6 About_section_images">
              <img
                src="/Images/Images provided by Bhaskar ji/Images_partner_1.png"
                alt="images"
              />
            </div>
          </div> */}
        </div>
      </section>

      {/* <section className="About_section_thard">
        <div className="container-fluid About_section_th">
          <div className="row">
            <div className="col-md-6">
              <img
                src="/Images/Images provided by Bhaskar ji/Images_partner.png"
                alt="images"
              />
            </div>
            <div className="col-md-6">
              <h1 style={{ paddingBottom: "0" }}>Innovative Marketing</h1>
              <p style={{ paddingTop: "0" }}>
                With our expertise in digital marketing and property promotion,
                we’ll showcase your spaces to a broad audience, attracting the
                right tenants and maximizing your property’s potential. Get
                ready to see your spaces shine!
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid About_section_th">
          <div className="row">
            <div className="col-md-6">
              <h1 style={{ paddingBottom: "0" }}>Expert Support</h1>
              <p style={{ paddingTop: "0" }}>
                Our dedicated team is here to support you every step of the way.
                From initial consultations to ongoing management, we provide the
                resources and guidance you need to make your leasing experience
                smooth and successful.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="/Images/Images provided by Bhaskar ji/Images_partner2.png"
                alt="images"
              />
            </div>
          </div>
        </div>
      </section> */}

      <div className="container-fluid Brand_partner">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1

              style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }} data-aos="fade-up">Our Brand Partner</h1>
            <p
              style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}
              data-aos="zoom-in"
            >
              Committed to fostering collaboration, creativity, and
              productivity, our partner offers a diverse range of workspace
              solutions, including:
            </p>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>

      <section className="Slider_full_section" data-aos="fade-up">
        <div className="container-fluid Brand_partner_slider">
          <div className="row">
            <div className="col-12">
              <Swiper
                spaceBetween={0}
                slidesPerView={4}
                loop={brandData.length > 1}
                autoplay={{
                  delay: 1,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  100: { slidesPerView: 2, spaceBetween: 20 },
                  620: { slidesPerView: 4, spaceBetween: 20 },
                }}
                speed={1500}
                modules={[Autoplay]}
                className="mySwiper"
                data-aos="zoom-in"
              >
                {/* Map through bannerData */}
                {brandData.map((eachData, index) => (
                  <SwiperSlide key={`slide-${index}`} >
                    <div className="slider_section_main" style={{ display: 'flex', alignItems: 'center', height: '90px', justifyContent: 'center' }}>
                      <img src={eachData.img} style={{ borderRadius: '10px' }} />
                    </div>
                  </SwiperSlide>
                ))}

              </Swiper>
            </div>
          </div>
        </div>
      </section>

      {/* new setion start INDUSTRY BASED VALUE  */}
      <section className="INDUSTRY_BASED_VALUE">
        <div className="container-fluid Industry_value_section">
          <div className="row">
            <div className="col-md-4"data-aos="fade-up">
              <div className="Industry_value">
                <p style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>
                  1.
                  Brand Reputation: A well-established brand reputation can
                  attract customers and command premium prices.
                </p>
              </div>
            </div>
            <div className="col-md-4"data-aos="fade-up">
              <div className="Industry_value">
                <p style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>
                  2.
                  Intellectual Property: Patents, trademarks, and copyrights can
                  provide a competitive edge and increase a company's value.
                </p>
              </div>
            </div>
            <div className="col-md-4"data-aos="fade-up">
              <div className="Industry_value">
                <p style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>
                  3.
                  Customer Relationships: Strong relationships with customers
                  can lead to repeat business, referrals, and increased customer
                  loyalty.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Start testimonial section  */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1 style={{
              background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
              '-webkit-background-clip': 'text',
              color: 'transparent',
              backgroundClip: 'text'
            }} data-aos="zoom-in">They Satisfied with service</h1>
            <p style={{
              background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
              '-webkit-background-clip': 'text',
              color: 'transparent',
              backgroundClip: 'text'
            }} data-aos="zoom-in">
              For our staff, our co-working space has changed everything. The
              contemporary amenities, adaptable workstations, and encouraging
              community have produced the perfect setting for cooperation and
              production.
            </p>
          </div>
          <div className="col-md-4" data-aos="zoom-in">
            <div className="Testimonial_images">
              <img src="/Images/Testimonial_header_img.png" alt="images" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Swiper
              spaceBetween={30}
              slidesPerView={2}
              speed={3000}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                loop: true,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                620: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                720: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
              
            >
              {testimonlsData.map((eachData) => {
                return (
                  <>
                    <SwiperSlide className="slider_main"data-aos="fade-down">
                      <div className="Testimonial_section_slider">
                        <div className="Testimonial_section_slider_items">
                          <p style={{
                            background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                            '-webkit-background-clip': 'text',
                            color: 'transparent',
                            backgroundClip: 'text'
                          }}>
                            {eachData.feedback}
                          </p>

                          <div className="Testimonial_name">
                            <div className="Testimonil_images">
                              <img
                                src={eachData.img}
                                alt="images"
                                style={{ borderRadius: '10px' }}
                              />
                            </div>
                            <div className="Testimonial_name_heading">
                              <h5 style={{
                                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                                '-webkit-background-clip': 'text',
                                color: 'transparent',
                                backgroundClip: 'text'
                              }}>{eachData.user_name}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>

      <Footer id="footer" />
    </div>
  );
};
export default Partnership;
