import { Link } from "react-router-dom";
import "../App.css";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { getBaseUrl } from "../utils";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="custom-modal-shadow"
    >
      <Modal.Header className="text-center">
        <div style={{ width: '100%' }}>
          <h1>Details Submitted</h1>
        </div>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#ffffff' }} className="text-center">
        <img src="/Images/Images provided by Bhaskar ji/submited.png" alt="submited" />
        <h5>Your details has been successfully submitted.</h5>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button onClick={props.onHide} style={{ width: '30%', height: '50px', background: '#FBAE16', border: 'none' }}>Go Back Home</Button>
      </Modal.Footer>
    </Modal>
  );
}
const Footer = ({ id }) => {
  const [modalShow, setModalShow] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [message, setmessage] = useState("");
  function handalSubmit() {
    // console.log("firstName------>",firstName);
    if (!firstName) {
      alert("Name field is required");
      return false;
    }
    if (!lastName) {
      alert("lastName field is required");
      return false;
    }
    if (!email) {
      alert("email field is required");
      return false;
    }
    if (!number) {
      alert("number field is required");
      return false;
    }
    if (!message) {
      alert("message field is required");
      return false;
    }
    axios
      .post(getBaseUrl() + "user_api/contactSubmit", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        number: number,
        message: message,
      })
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          //alert(res.data.message);
          //navigate('/Thank', { state: {} });
          setfirstName("");
        setlastName("");
        setemail("");
        setnumber("");
        setmessage("");
        setModalShow(true)
        // window.scrollTo(0, 0);
      });
  }
  const [setting, setsetting] = useState({});
  function getsettingData() {
    axios
      .get(getBaseUrl() + "user_api/setting")
      .then((res) => {
        console.log("res--setting-->", res.data);
        if (res.data.status)
          setsetting(res.data.results);
      });
  }
  useEffect(() => {
    getsettingData();
  }, []);

  return (
    <>
      {/* Contact _section start */}
      <section className="Containe_section_top_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 Contat_main_section_footer" id="Footer_____section">
              <div className="row" id='get_In_Touch'>
                <div className="col-2 Images_section_contact">
                  <img src="/Images/Footer_contact.png" alt="images" />
                </div>
                <div className="col-10 Contact_form" id={id}>
                  <div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h1 className="Name__section" style={{ fontSize: '22px', marginLeft: '30px' }}>Contact Us </h1>
                  </div>
                  <div className="Form_section">
                    <div className="Form_name_section">
                      <div className="Name_section">
                        <label>
                          First Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={(e) => { setfirstName(e.target.value) }}
                          value={firstName}
                          type="text"
                          name="name"
                          required
                          placeholder="First Name"
                        />
                      </div>
                      <div className="Name_section">
                        <label>
                          Last Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={(e) => { setlastName(e.target.value) }}
                          value={lastName}
                          type="text"
                          name="name"
                          required
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="Form_name_section">
                      <div className="Name_section">
                        <label>
                          Email <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={(e) => { setemail(e.target.value) }}
                          value={email}
                          type="email"
                          name="email"
                          required
                          placeholder="Example@gmail.com"
                        />
                      </div>
                    </div>
                    <div className="Form_name_section">
                      <div className="Name_section">
                        <label>
                          Phone <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          onChange={(e) => { setnumber(e.target.value) }}
                          value={number}
                          type="number"
                          name="number"
                          required
                          placeholder="(XXX) XXXX XXX"
                        />
                      </div>
                    </div>
                    <div className="Form_name_section">
                      <div className="Name_section">
                        <label>Message</label>

                        <textarea onChange={(e) => { setmessage(e.target.value) }} value={message} placeholder="Message"></textarea>
                      </div>
                    </div>

                    <center> <div className="Submit" onClick={() => { handalSubmit() }}>
                      Submit <NorthEastIcon className="NorthEastIcon" />{" "}
                    </div></center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />


      {/* Footer coding start */}

      <section className="Footer_Main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 Footer_column">
              <div className="About_footer_section">
                <div className="About_Footer_logo">
                  <img src="/Images/Logo.png" alt="Footer_logo" />
                  <p style={{
                    background: 'linear-gradient(135deg,rgb(247, 247, 242) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>
                   Strategically located in the heart of Noida, Riana Towers exemplifies modern architecture, offering premium amenities and unmatched convenience for a refined urban lifestyle.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 Footer_column">
              <div className="Contact_footer_section">
                <h1 style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(244, 237, 237) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>Contact</h1>
                <div className="Fa_icons_section_footer" style={{
                  display: 'flex', flexDirection: 'column'
                }}>
                  <div style={{
                    display: 'flex', alignItems: 'center', gap: '10px'
                  }}>
                    <i class="las la-phone-volume" style={{
                      background: 'linear-gradient(135deg,rgb(238, 238, 231) 10%,rgb(252, 167, 82) 100%)',
                      '-webkit-background-clip': 'text',
                      color: 'transparent',
                      backgroundClip: 'text',
                    }}></i>  <p style={{
                      padding: '0', margin: '0',
                      background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                      '-webkit-background-clip': 'text',
                      color: 'transparent',
                      backgroundClip: 'text'
                    }}>+91 {setting.contact_number}</p>
                  </div>

                  <p style={{
                    background: 'linear-gradient(135deg,rgb(249, 249, 247) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>  Call Between 10 AM to 7 PM</p>
                </div>
                <div className="Fa_icons_section_footer">
                  <div>
                    <i class="lar la-envelope" style={{
                      background: 'linear-gradient(135deg,rgb(238, 238, 231) 10%,rgb(252, 167, 82) 100%)',
                      '-webkit-background-clip': 'text',
                      color: 'transparent',
                      backgroundClip: 'text',
                    }}></i>
                  </div>
                  <div className="text_section_contact">
                    <p style={{
                      background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                      '-webkit-background-clip': 'text',
                      color: 'transparent',
                      backgroundClip: 'text'
                    }}>{setting.contact_gmail}</p>
                  </div>
                </div>
                <div className="Fa_icons_section_footer">
                  <Link to='https://www.bing.com/maps?pglt=299&q=Head+Office%2C+Plot+No.+111%2C+Noida+Sector+136&cvid=5185be695bd9477586b2d08d5d046e36&gs_lcrp=EgRlZGdlKgYIABBFGDkyBggAEEUYOTIGCAEQABhAMgYIAhAAGEAyBggDEAAYQDIGCAQQABhAMgYIBRAAGEAyBggGEAAYQDIGCAcQABhAMgYICBAAGEDSAQgxODU0ajBqMagCALACAA&FORM=ANNTA1&PC=W230' style={{ color: 'white' }}>
                    <div className="text_section_contact" style={{
                      display: 'flex', gap: '10px'
                    }}>
                      <i class="fa fa-building" aria-hidden="true" style={{
                        background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                        '-webkit-background-clip': 'text',
                        color: 'transparent',
                        backgroundClip: 'text'
                      }}></i>  <p style={{
                        background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                        '-webkit-background-clip': 'text',
                        color: 'transparent',
                        backgroundClip: 'text'
                      }}>{setting.contact_address}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3 Footer_column">
              <div className="Follow_section_Footer">
                <h1 style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(244, 237, 237) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>Follow Us</h1>
                <div className="Fa_section">
                  <i style={{
                    background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }} onClick={() => window.open(setting.facebook_link, "_blank")} class="lab la-facebook"></i>
                  <i style={{
                    background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }} onClick={() => window.open(setting.twitter_link, "_blank")} class="lab la-twitter"></i>
                  <i style={{
                    background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }} onClick={() => window.open(setting.instagram_link, "_blank")} class="lab la-instagram"></i>
                </div>
              </div>
              <div className="footer_images">
                <Link to="/our_blog">
                  <p style={{
                    background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>Blogs</p>
                </Link>
                <Link to="/terms_conditions">
                  <p style={{
                    background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>Terms & Conditions</p>
                </Link>
                <Link to="/privacy_policy">
                  <p style={{
                    background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>Privacy Policy</p>
                </Link>
              </div>
            </div>

            <div className="col-12 copy_right_section">
              <p style={{
                background: 'linear-gradient(135deg,rgb(253, 253, 249) 10%,rgb(252, 167, 82) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}>Copyright © 2025 Riana Towers | All Right Reserved</p>
            </div>
          </div>
        </div>
      </section>
      {/* end footer coding  */}
    </>
  );
};
export default Footer;
