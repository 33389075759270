import { useEffect } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link, useNavigate } from "react-router-dom";
import { getBaseUrl } from "../utils";
import axios from "axios";
import { useState } from "react";

const Blog = () => {
  const [categoryBlog, setcategoryBlog] = useState([]);
  const [blogDatalist, setblogDatalist] = useState([]);
  const [catID, setcatID] = useState("");
  const [search, setsearch] = useState("");
  const navigate = useNavigate();
  function blogPageset_data(blogData) {
    console.log("blogData----------->", blogData);
    navigate('/our_blog_details', { state: { blogData: blogData } });
  };
  function CategoryBlog() {
    axios
      .get(getBaseUrl() + "user_api/CategoryBlog")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setcategoryBlog(res.data.results);
      });
  }
  function blogData(cat_id = "", search = "") {
    console.log("catID--------getblogData------------>", cat_id);
    console.log("search--------getblogData------------>", search);
    axios
      .post(getBaseUrl() + "user_api/blogData", {
        cat_id: cat_id,
        search: search,
      })
      .then((res) => {
        console.log("res----blogData--->>", res.data);
        if (res.data.status) setblogDatalist(res.data.results);
      });
  }
  function setcatfilter(cat_id) {
    console.log("cat_id---->", cat_id);
    setcatID(cat_id);
    blogData(cat_id);
  }
  function getsearch(data) {
    console.log("search---->", data);
    setsearch(data);
    blogData(catID, data);
  }
  useEffect(() => {
    CategoryBlog();
    blogData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <section className="Services_section_maain">
        <div className="container-fluid Services_section">
          <div className="row">
            <div className="col-12">
              <h1>Our Blogs</h1>
              <p>
                Your one-stop shop for all things co-working. Discover inspiring
                stories, productivity tips, and community news from our vibrant
                workspace
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Blog selected section main */}
      <div className="Blog_selected_section_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <select onChange={(e) => setcatfilter(e.target.value)} >
                <option>Filter by category</option>
                {categoryBlog.map((eachData) => {
                  return (
                    <>
                      <option value={eachData._id} >{eachData.name}</option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="col-md-8">
              <input
                onKeyUp={(e) => { getsearch(e.target.value) }}
                type="text"
                name="text"
                required
                className="Search_section"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Blog section start */}
      <section className="Blog_section_main">
        <div className="container-fluid">
          <div className="row">
            {blogDatalist.map((eachData) => {
              return (
                <>
                  <div className="col-md-4 Blog_section_top_right">
                    <div className="div_section">
                      <div className="Blog_images_section">
                        <img
                          src={eachData.image}
                          alt="images"
                        />
                        <div className="Blog_section_text_main">
                          <p>
                            {eachData.name}
                          </p>
                            <button onClick={() => blogPageset_data(eachData)} >
                              Read the Full Blog
                              <ArrowRightAltIcon className="ArrowRightAltIcon" />
                            </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Blog;
