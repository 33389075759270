import { useEffect, useState } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useLocation, useNavigate } from "react-router-dom";
import { getBaseUrl } from "../utils";
import axios from "axios";

const BlogDetails = () => {
  const [otherblog, setotherblog] = useState([]);
  const [singleblog, setsingleblog] = useState([]);
  console.log("otherblog---->", otherblog);
  function getblogData(currentBlogID,cat_id_for_other) {
    axios
      .post(getBaseUrl() + "user_api/blogData", {
        cat_id: "",
        currentBlogID:currentBlogID,
        cat_id_for_other:cat_id_for_other,
      })
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setotherblog(res.data.results);
      });
  }
  const navigate = useNavigate();
  const location = useLocation();
  if (!location.state) {
    navigate("/");
  }
  function blogPage(blogData) {
    console.log("blogData---onclick------->", blogData)
    setsingleblog(blogData);
    var currentBlogID = blogData._id;
    var cat_id_for_other = blogData.cat_id;
    getblogData(currentBlogID,cat_id_for_other);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  
    // Check if location.state and blogData exist
    console.log("Location Data: ", location.state);

    const blogData = location.state?.blogData;
    if (blogData) {
      console.log("blogDatasingli------detailpage----->", blogData);
      setsingleblog(blogData);
    } else {
      console.warn("Blog data not found, redirecting...");
      navigate("/"); // Redirect if no data found
    }
    var currentBlogID = blogData._id;
    var cat_id_for_other = blogData.cat_id;

    console.log("currentBlogID---------->", currentBlogID);
    console.log("cat_id_for_other---------->", cat_id_for_other);


    getblogData(currentBlogID,cat_id_for_other);

  }, [location]);
  return (
    <div>
      <Navbar />
      <section className="Services_section_maain">
        <div className="container-fluid Services_section Blog_details_pages">
          <div className="row">
            <div className="col-12">
              <h6> {singleblog.categoryName}</h6>
              <h4>
              {singleblog.name}
              </h4>
              <h6>{singleblog.Created_date}</h6>
            </div>
          </div>
        </div>
      </section>

      <section className="Blog_section_text">
        
        <section className="Blog_sectio_text_details">
          <div className="container-fluid">
            <div className="row">
            <div dangerouslySetInnerHTML={{ __html: singleblog.description }} />
            </div>
          </div>
        </section>

        <div className="container-fluid">
      <h1 style={{ fontFamily: "Zodiak" }}>Other Blogs</h1>
      <div className="row">
        {otherblog.map((eachData) => {
          const truncatedName = eachData.name.length > 40 ? eachData.name.substring(0, 40) + '...' : eachData.name;

          return (
            <div className="col-md-4 Blog_section_top_right" key={eachData.id}>
              <div className="div_section">
                <div className="Blog_images_section">
                  <img src="/Images/Images_blog.png" alt="images" />
                  <div className="Blog_section_text_main">
                    <p>{truncatedName}</p>
                    <button onClick={() => blogPage(eachData)}>
                      Read the Full Blog
                      <ArrowRightAltIcon className="ArrowRightAltIcon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
      </section>
      <Footer />
    </div>
  );
};
export default BlogDetails;
