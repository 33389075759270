import Navbar from "./Navbar";
import { getBaseUrl } from "../utils";
import axios from "axios";
import BoltIcon from "@mui/icons-material/Bolt";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useRef } from "react";

const Panel = ({ index, backgroundImage, setActivePanel, activePanel }) => {
  const handleClick = () => {
    setActivePanel(index);
  };

  return (
    <div
      className={`panel ${activePanel === index ? "active" : ""}`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      onMouseEnter={handleClick}
    >
    </div>
  );
};

const Private_Space = () => {

  const [activePanel, setActivePanel] = useState(0);
  const [images, setimages] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const [serviceID, setserviceID] = useState("");
  const [serviceData, setserviceData] = useState({});
  const [faqData, setfaq] = useState([]);

  // Function to fetch service data
  function get_Single_ServiceData(serviceID) {
    if (!serviceID) return; // Check if serviceID is available

    console.log("Fetching service data for ID:", serviceID);
    axios
      .post(getBaseUrl() + "user_api/serviceList", {
        id: serviceID,
      })
      .then((res) => {
        console.log("API Response:", res.data);
        if (res.data.status) setserviceData(res.data.results[0]);
      })
      .catch((error) => {
        console.error("Error fetching service data:", error);
      });
  }

  function Galary(serviceID) {
    if (!serviceID) return; // Check if serviceID is available

    console.log("Fetching service data for ID:", serviceID);
    axios
      .post(getBaseUrl() + "user_api/Galary", {
        id: serviceID,
      })
      .then((res) => {
        console.log("API Galary Response:", res.data);
        if (res.data.status) setimages(res.data.results);
      })
      .catch((error) => {
        console.error("Error fetching service data:", error);
      });
  }

  // useEffect to set serviceID from location.state and fetch data
  useEffect(() => {
    window.scrollTo(0, 0);
    const { ID } = location.state ?? "";
    if (ID) {
      setserviceID(ID); // Set the service ID
    }
  }, [location]);

  // Fetch service data when serviceID changes
  useEffect(() => {
    if (serviceID) {
      get_Single_ServiceData(serviceID);
      Galary(serviceID)
      get_faqData(serviceID)
    }
  }, [serviceID]);




  function get_faqData(serviceID) {
    axios.get(getBaseUrl() + "user_api/faqData?serviceId="+serviceID).then((res) => {
      console.log("res--------------------------get_faqData----------->", res.data);
      setfaq(res.data.results);
    });
  }

const [activeKey, setActiveKey] = useState(null);
  const toggleAccordion = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };
  const swiperRef = useRef(null);
  console.log("faqData--------------------------get_faqData----------->", faqData);

  return (
    <div>
      <Navbar />
      <section className="Services_section_maain">
        <div className="container-fluid Services_section">
          <div className="row">
            <div className="col-12">
              <h1 
                style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(255, 253, 253) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}
                data-aos="zoom-out"
              >{serviceData.name}</h1>
              <p
               style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(255, 255, 255) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}
              data-aos="zoom-out"
              >
                A personal, secluded area where one can retreat to enjoy solitude or engage in private activities.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* About section coding start */}
      <section className="About_section_main">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-6 Expreance_text_section">
            <div dangerouslySetInnerHTML={{ __html: serviceData.description_short }}
             style={{
              background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
              '-webkit-background-clip': 'text',
              color: 'transparent',
              backgroundClip: 'text'
            }}
            data-aos="zoom-out"
            />
              {/* <h1>Inclusive and sustainable innovation at scale.</h1>
              <p>
                Inclusive and sustainable innovation at scale means creating innovative solutions that benefit everyone, while considering the long-term environmental and social impact.
              </p> */}
            </div>
            <div className="col-md-6">
              <div className="slider_images">
                <div className="slider_section"data-aos="zoom-out">
                  <img
                    src={serviceData.image}
                    alt="images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section className="Gallery_heding_section_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <h1 className="gallery">Gallery</h1>
              <p>
                Professionals may interact, invent, and flourish in a vibrant
                and motivating environment at our co-working space.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="accordion-slider">
                <div className="accordion">
                  <ul>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}



      <section className="Gallery_heding_section_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <h1 className="gallery"
               style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}
              data-aos="zoom-out"
              >Gallery</h1>
              <p
               style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}
              data-aos="zoom-out"
              >
                Professionals may interact, invent, and flourish in a vibrant
                and motivating environment at our co-working space.
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid">
        <div className="row">
          <div className="col-12 Gallery_new">
            <div className="gallory_new_all"data-aos="zoom-out">
              {images.map((image, index) => (
                <Panel
                  key={index}
                  index={index}
                  backgroundImage={image}
                  setActivePanel={setActivePanel}
                  activePanel={activePanel}

                />
              ))}
            </div>

          </div>
        </div>

      </div>

      </section>

      


      {/* Key points section */}
      <section className="Key_pointes_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: serviceData.description }} 
              data-aos="zoom-out"
               style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="Faq_section">
        <div className="container-fluid FAQ_main_section">
          <div className="row">
            <div className="col-12" data-aos="zoom-in">
              <h1
                style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }} data-aos="zoom-in">Frequently Asked Questions</h1>
              <h5 data-aos="zoom-in">Can’t find the answer here? Check out our Help Centre.</h5>

              <div className="accordion" data-aos="zoom-in-right">
                {faqData.map((eachData) => {
                  return (
                    <>
                      <div className="accordion-item">
                        <div
                          className="accordion-header Header_section_faq"
                          onClick={() => toggleAccordion(eachData._id)}
                          data-aos="zoom-out"
                        >
                          <div className="Images_icons">
                            <h6
                              style={{
                                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                                '-webkit-background-clip': 'text',
                                color: 'transparent',
                                backgroundClip: 'text'
                              }}
                            >
                              {" "}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: eachData.question,
                                }}
                              />
                            </h6>
                            {activeKey === eachData._id ? (
                              <ArrowDropUpIcon className="Iconses" />
                            ) : (
                              <ArrowDropDownIcon className="Iconses" />
                            )}
                          </div>
                        </div>
                        {activeKey === eachData._id && (
                          <div className="accordion-body Body_text_section">
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: eachData.ans,
                                }}
                              />
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />
    </div>
  );
};
export default Private_Space;
