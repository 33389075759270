import { useEffect, useState } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { getBaseUrl } from "../utils";
import axios from "axios";

const About = () => {
  useEffect(() => {
    getteamData()
    window.scrollTo(0, 0);
  }, []);
  function getteamData() {
    axios
      .post(getBaseUrl() + "user_api/teamList")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setteam(res.data.results);
      });
  }
  const [teamData, setteam] = useState([]);

  return (
    <div>
      <Navbar />
      <section className="About_section_main_all" style={{ overflow: 'hidden' }}>
        <div className="container-fluid">
          <div className="row About_section_ttete">
            <div className="col-md-6 About_section_text" data-aos="zoom-in-right">
              <h1
                style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(244, 237, 237) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>Your Workplace, Reimagined.</h1>
              <p>
                Our Co-working Space Riana is more than just a workspace; it's a
                vibrant community of innovators, entrepreneurs, and
                professionals.
              </p>
            </div>
            <div className="col-md-6 About_section_images" data-aos="zoom-out-left">
              <img
                src="/Images/Images provided by Bhaskar ji/_MG_9751_2_3_fused_1.png"
                alt="images"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="About_section_main_secound" style={{ overflow: 'hidden' }}>
        <div className="container-fluid About_section_secound">
          <div className="row">
            <div className="col-12" style={{ padding: '10px' }}>
              <h1
                style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}
                data-aos="zoom-in-down"
              >About us</h1>
              <p style={{ padding: '10px', margin: "0" }} data-aos="zoom-in-down">Welcome to Riana Towers, where innovation meets excellence to redefine modern workspaces. As Northern India’s premium construction and infrastructure developer, we take pride in our unwavering integrity in customer engagement and quality assurance. </p>
              <label style={{
                padding: '0', margin: "0", fontSize: '20px', fontWeight: '700', background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }} data-aos="zoom-in-down" >Our Highlights</label>
              <ul style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>

                <li style={{ padding: '0', margin: "0" }} data-aos="zoom-in-down">
                  <b style={{
                    background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }} data-aos="zoom-in-down"> 1.5 million sq. ft.</b> of built-up workspace.
                </li>
                <li style={{ padding: '0', margin: "0" }} data-aos="zoom-in-down">
                  <b style={{
                    background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }} data-aos="zoom-in-down"> 1.2 million sq. ft.</b> leased for thriving business operations.
                </li>

                <li style={{ padding: '0', margin: "0" }} data-aos="zoom-in-down" >
                  Supporting a community of <b>5,000+ professionals.</b>
                </li>

              </ul>
              <p style={{ padding: '0', margin: "0" }} data-aos="zoom-in-down"><b style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}>Platinum-certified green buildings for sustainability and excellence.</b> With a focus on eco-friendly, cutting-edge designs, our "Golden & Green Workspaces" initiative seamlessly blends sustainability with sophistication. At Riana Towers, we don’t just build offices; we create spaces where collaboration and innovation flourish .</p>
            </div>
            {/* <div className="Expreanse_section_main">
                <div className="Exprteans_section">
                  <div className="Icons_Expreansces">
                    <img src="/Images/tactic.png" alt="images" />
                    <h1>500+</h1>
                  </div>
                  <p>Projects</p>
                </div>
                <div className="Exprteans_section">
                  <div className="Icons_Expreansces">
                    <img src="/Images/kid_star.png" alt="images" />
                    <h1>150+</h1>
                  </div>
                  <p>Happy Clients</p>
                </div>
                <div className="Exprteans_section">
                  <div className="Icons_Expreansces">
                    <img src="/Images/Qustion.png" alt="images" />
                    <h1>24/7</h1>
                  </div>
                  <p>Support Help</p>
                </div>
              </div> */}
          </div>
        </div>
      </section>

      <section className="About_section_thard" style={{ overflow: 'hidden' }}>
        <div className="container-fluid About_section_th">
          <div className="row">
            <div className="col-md-6" data-aos="zoom-out-left">
              <h1 style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}>Our Vision</h1>
              <ul>
                <li><b style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>Redefining Commercial Spaces</b>: Creating contemporary, sustainable, and eco-friendly buildings.</li>
                <li><b style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>Modernizing Real Estate</b>: Enhancing property buying, selling, and leasing with innovation and collaboration.</li>
                <li><b style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>Client-Centric Focus</b>: Delivering world-class services and industry-leading expertise.</li>
                <li><b style={{
                  background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                  '-webkit-background-clip': 'text',
                  color: 'transparent',
                  backgroundClip: 'text'
                }}>Commitment to Excellence</b>: Setting new benchmarks in real estate and infrastructure through quality and integrity.</li>

              </ul>
            </div>
            <div className="col-md-6" data-aos="zoom-in-right">
              <img
                src="/Images/Images provided by Bhaskar ji/About_2.png"
                alt="images"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid About_section_th">
          <div className="row">
            <div className="col-md-6" data-aos="zoom-out-left">
              <img
                src="/Images/Images provided by Bhaskar ji/About_3.png"
                alt="images"
              />
            </div>
            <div className="col-md-6" data-aos="zoom-in-right">
              <h1 style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}>Our Mission</h1>
              <ul>
                <li><b
                  style={{
                    background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>Delivering Value</b>: Committed to providing exceptional value with integrity and passion.</li>
                <li><b
                  style={{
                    background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>Exceeding Goals</b>: Driven by collaboration and ambition to achieve outstanding results.</li>
                <li><b
                  style={{
                    background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>Prioritizing Excellence</b>: Focused on setting new standards in quality and innovation.</li>
                <li><b
                  style={{
                    background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent',
                    backgroundClip: 'text'
                  }}>Unparalleled Experience</b>: Aiming to redefine the real estate experience for clients and customers.</li>

              </ul>
            </div>
          </div>
        </div>
        <centre>
          <div className="container-fluid About_images_sectionsss">
            <div className="row">
              <div className="col-12 Images_section" data-aos="zoom-in">
                <img src="/Images/Images_About.png" alt="images" />
              </div>
            </div>
          </div>
        </centre>
      </section>

      <section className="Our_teams_section_main" style={{ overflow: 'hidden' }}>
        <div className="container-fluid Our_team_section_main">
          <div className="row">
            <div className="col-12">
              <h1 style={{
                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(27, 26, 26) 100%)',
                '-webkit-background-clip': 'text',
                color: 'transparent',
                backgroundClip: 'text'
              }}>Meet Our Team</h1>
              <div className="row">
                {teamData.map((eachData) => {
                  return (
                    <>
                      <div className="col-md-4" data-aos="zoom-in">
                        <div className="Images_section">
                          <div className="Images_section_all">
                            <img src={eachData.img} alt="images" />
                            <div className="Over_section_main">
                              <h5 style={{
                                background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                                '-webkit-background-clip': 'text',
                                color: 'transparent',
                                backgroundClip: 'text'
                              }}>{eachData.name}</h5>
                              <p>{eachData.designation}</p>
                              <div className="Icons_section">
                                <i
                                  class="fa fa-facebook-official"
                                  aria-hidden="true"
                                ></i>
                                <i class="fa fa-instagram" aria-hidden="true"></i>
                                <i class="fa fa-twitter" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8" data-aos="zoom-in-right">
                        <h1 style={{
                          background: 'linear-gradient(135deg,rgb(255, 162, 0) 10%,rgb(0, 0, 0) 100%)',
                          '-webkit-background-clip': 'text',
                          color: 'transparent',
                          backgroundClip: 'text'
                        }} >Name : {eachData.name}</h1>
                        <h5 style={{
                          background: 'linear-gradient(135deg,rgb(255, 145, 0) 10%,rgb(0, 0, 0) 100%)',
                          '-webkit-background-clip': 'text',
                          color: 'transparent',
                          backgroundClip: 'text'
                        }}>Designation : {eachData.designation}</h5>
                        <p className="Out_tem_info">
                        <div dangerouslySetInnerHTML={{ __html: eachData.description }} />
                        </p>
                      </div>
                    </>
                  );
                })}
                {/* <div className="col-md-4">
                  <div className="Images_section">
                    <div className="Images_section_all">
                      <img src="/Images/Images provided by Bhaskar ji/fixed-height sec- (1).png" alt="images" />
                      <div className="Over_section_main">
                        <h5>Mr. Bhupendra Singh</h5>
                        <p>Sr. Manager Accounts</p>
                        <div className="Icons_section">
                          <i
                            class="fa fa-facebook-official"
                            aria-hidden="true"
                          ></i>
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                          <i class="fa fa-twitter" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <p className="Out_tem_info">
                  He is a Senior Manager Accounts at Riana Towers. Who is a detail-oriented professional with core strengths in Financial Planning and Analysis, Accounts & Audit, Income Tax Matters, Company Law, GST, VAT, Compliances and Profit Centre operations. Offering over 10+ years of invaluable experience in all Finance disciplines with key skills in finance management, negotiations, cash management, account reconciliation, creditor/debtor management, Vendor and investment operations.
                  </p>
                </div> */}

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default About;
